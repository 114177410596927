
.item-categorie-home{
    
    background-size: cover;
    position: relative;
    overflow: hidden;
}


.overlay{
    background-color: rgba(0, 0, 0, 0.272);
    width: 100%;
    height: 100%;
    position: absolute;
}
.item-categorie-home p{
    position: relative;
    text-shadow: 2px 2px 0 #000,  
                 -2px 2px 0 #000, 
                 2px -2px 0 #000, 
                 -2px -2px 0 #000,
                 2px 0px 0 #000,
                 0px 2px 0 #000,
                 -2px 0px 0 #000,
                 0px -2px 0 #000;
}


@media (max-width: 670px) {
 .digital-menu{
    display: none;
    }

   

    .ordernaow {
        width: 130px !important;
    }
    .ordernaow span{
        font-size: 12px !important;
    }

}


@media (max-width: 1054px) {
   


}


@media (max-width: 767px) {
    .content-home{
        padding: 0 25px !important;
    }
    
}

@media (max-width: 600px) {
    .ordernaow{
        display: none !important;
    }


    .nav-menu-bar{
        border-radius: 0px !important;
    }
     .banners-home{
        padding-inline: 0px !important;
    }


    .contentChild{
        padding: 0px !important;
    }
}


@media (max-width: 500px) {
    .content-home{
        padding: 0 25px !important;
    }

    .content-categotie-list{
        justify-content: space-between;
    }

    
    
}



@media (max-width: 430px) {
    


    
}




@media (max-width: 390px) {
    .item-categorie-content{
      width: 100% !important;
    }


}



@media (max-width: 360px) {
    .item-categorie-content{
       width: 100% !important;
    }
}
