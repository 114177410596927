

.contentChild{
    padding-inline: 21%;
}
@media (max-width: 670px) {
 .digital-menu{
    display: none;
    }


 
    .ordernaow {
        width: 130px !important;
    }
    .ordernaow span{
        font-size: 12px !important;
    }

}

@media (max-width: 1195px) {
    .content-footer-ordernow{
        width: 80% !important;
    }
}



@media (max-width: 1100px) {
    .content-footer-ordernow{
        width: 95% !important;
    }
}




@media (max-width: 989px) {
    .contentChild{
    padding-inline: 10%;
}
    .content-footer-ordernow{
        width: 100% !important;
    }
}




@media (max-width: 915px) {
.contentChild{
    padding-inline: 10%;
}

    .content-footer{
         padding-inline: 90px !important;
    }
}

@media (max-width: 980px) {
      .content-footer-ordernow a{
        width: 230px !important;
      }
}



@media (max-width: 767px) {
    .contentChild{
        padding-inline: 0%;
    }
   
}




@media (max-width: 600px) {


    .ordernaow{
        display: none !important;
    }


    .nav-menu-bar{
        border-radius: 0px !important;
    }
    

    .contentChild{
        padding: 0px !important;
    }

    .content-footer-ordernow{
        flex-direction: column;
        align-items: center;
    }

    .content-footer-ordernow  a{
        margin-bottom: 10px;
    }
}


@media (max-width: 500px) {
    .content-home{
        padding: 0 25px !important;
    }

    .content-categotie-list{
        justify-content: space-between;
    }

    .item-categorie-content .item-categorie{
        height: 124px !important;
    }

    


    
}



@media (max-width: 390px) {
   


}



@media (max-width: 360px) {
   
}
