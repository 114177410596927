/* styles.css */

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    height: 100vh;
  }
  
  .logo {
    width: 400px;
    height: 400px;
    object-fit: contain;
  }
  

  .fullheight{
    height: 100vh;
  }